import React, { useState, useEffect } from 'react';
import Favicon from 'react-favicon';
import './App.css';
import tactful_icon from './media/tactful_icon-32.png';

function App() {
  useEffect(() => {
    document.title = "Tactful - Contacts and Messaging App"
  }, []);

  return (
    <div className="App">
      <Favicon url={tactful_icon}/>
      <header className="App-header">
        <p>Social media isn't the best way to stay socially connected.</p>
        <p><strong>Tactful</strong>, on the other hand, is an app that helps you create and maintain quality relationships. It's a CRM for normal people.</p>
        <p>Enter your info to get the beta for free when it's ready: </p>
      </header>
      <SignupForm/>
      <div className="Footer">
        <p>Created by <a href="https://twitter.com/JonLampel">Jonathan Lampel</a></p>
      </div>
      <div className="Background">
        <img src={require("./media/man-holding-phone-dark.jpg")} alt="" className="Background-img"/>
      </div>
    </div>
  );
}

function SignupForm() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  return(
    <div id="mc_embed_signup">
      <form action="https://jonathanlampel.us4.list-manage.com/subscribe/post?u=78d54483d90bd7eab67207493&amp;id=84fa23dd61" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group">
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} name="EMAIL" class="required email" className="Input" id="mce-EMAIL" placeholder="Email Address"/>
          </div>
          <div class="mc-field-group">
            <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} name="FNAME" class="" id="mce-FNAME" className="Input" placeholder="First Name"/>
          </div>
          <div class="mc-field-group">
            <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} name="LNAME" class="" id="mce-LNAME" className="Input" placeholder="Last Name"/>
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style={{display:'none'}}></div>
            <div class="response" id="mce-success-response" style={{display:'none'}}></div>
          </div>
          <div className="Form-input" aria-hidden="true">
            <input type="text" name="b_78d54483d90bd7eab67207493_84fa23dd61" tabindex="-1" value=""/>
          </div>
          <div class="clear"><input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" className="Input-button" /></div>
        </div>
      </form>
    </div>
  );
}

export default App;
